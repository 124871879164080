<template>
  <b-card-code>
    <b-row>
      <b-col sm="6">
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            @click="download(rows)"
          >
            Exportar a Excel
          </b-button>
        </b-button-group>
      </b-col>

      <b-col sm="6">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Filtrar</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Filtrar"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :is-loading="loading"
      :rows="rows"
      :rtl="direction"
      :fixed-header="true"
      max-height="500px"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        No se encontraron datos
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>


        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :to="'/cda/reports-rentability-edit/' + props.row.ID"
          >
            Editar
          </b-button>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BRow,
  BCol,
  BButton,
  BButtonGroup,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { downloadExcel } from '@core/utils/utils'
import store from '@/store/index'
import environment from '@/environment'

export default {
  name: 'CentersTypesVue',
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BCardCode,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      admins: [],
      adminSelected: '',
      loading: true,
      pageLength: 100,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'ID',
          type: 'number',
        },
        {
          label: 'Descripción',
          field: 'DESCRIPCION',
        },
        {
          label: 'Cambio Signo',
          field: 'CAMBIO_SIGNO',
          width: '300px'
        },
        {
          label: 'Estado',
          field: 'AT02',
          width: '300px'
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Preactivo: 'light-preactivoCda',
        Inactivo: 'light-secondary',
        Activo: 'light-primary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        const resp = await this.$http.get(`${environment.uri}/profits/rules`)

        if (resp && resp.data) {
          this.rows = resp.data
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    currency(value) {
      return `$${this.$options.filters.currency(value)}`
    },
    download(rows) {
      const items = rows.map(item => ({
        ID: item.ID || '',
        centro: (item.centro || '').replace(/,/g, ' '),
        DESCRIPCION: item.DESCRIPCION || 0,
        CAMBIO_SIGNO: item.CAMBIO_SIGNO || 0,
        ESTADO: item.ESTADO || 0,
      }))
      downloadExcel(items, [
        'ID',
        'Criterio de Distribución',
        'Descripción',
        'Cambio Signo',
        'Estado',
      ], 'reportes-rentabilidad.csv')
    },
    filterByAdmin(admin) {
      this.searchTerm = ''

      if (admin || admin === 0) {
        this.rows = this.allRows.filter(item => item.idGerencia === admin)
      } else {
        this.rows = this.allRows
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
